<template>
  <div class="row-sonar sb-top2" v-if="liberaTela">
    <div
      class="sonar-box sb-right2"
      style="width: 240px"
      :class="{ flipping: flipCards[0] }"
      @mousedown="handleMouseDown('0')"
      @mouseup="handleMouseUp('0')"
      @mouseleave="handleMouseLeave('0')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[0]]"
          id="BlocoSonar-distancia-frente"
        >
          <img :src="iconDistancia" class="img-titulo dist" />
          <span class="titulo-texto dist">
            Distância à Pontos <br />
            de Interesse
          </span>
          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[0] != isFlipping && tipoExibidoLinhas[0] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[0] != isFlipping &&
                (tipoExibidoLinhas[0] == 'brasil' ||
                  tipoExibidoLinhas[0] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[0]"
              v-show="flipCards[0] != isFlipping"
            />
          </transition>
        </div>
        <DistanciaPontosInteresse
          :tipoExibido="tipoExibidoLinhas[0]"
          :resultados="resultados"
          :lado="'frente'"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[0]]"
          id="BlocoSonar-distancia-verso"
        >
          <img :src="iconDistancia" class="img-titulo dist" />
          <span class="titulo-texto dist">
            Distância à Pontos <br />
            de Interesse
          </span>
          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[0] != isFlipping && tipoExibidoLinhas[0] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[0] != isFlipping &&
                (tipoExibidoLinhas[0] == 'brasil' ||
                  tipoExibidoLinhas[0] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[0]"
              v-show="flipCards[0] != isFlipping"
            />
          </transition>
        </div>
        <DistanciaPontosInteresse
          :tipoExibido="tipoExibidoLinhas[0]"
          :resultados="resultados"
          :lado="'verso'"
        />
      </div>
    </div>
    <div
      class="sonar-box sb-3 sb-right2"
      :class="{ flipping: flipCards[1] }"
      @mousedown="handleMouseDown('1')"
      @mouseup="handleMouseUp('1')"
      @mouseleave="handleMouseLeave('1')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[1]]"
          id="BlocoSonar-perfil-frente"
        >
          <img :src="iconPerfil" class="img-titulo perfil" />
          <span class="titulo-texto perfil"> Perfil Digital </span>
          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[1] != isFlipping && tipoExibidoLinhas[1] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[1] != isFlipping &&
                (tipoExibidoLinhas[1] == 'brasil' ||
                  tipoExibidoLinhas[1] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[1]"
              v-show="flipCards[1] != isFlipping"
            />
          </transition>
        </div>
        <PerfilDigital
          :tipoExibido="tipoExibidoLinhas[1]"
          :resultados="resultados"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :lado="'frente'"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[1]]"
          id="BlocoSonar-perfil-verso"
        >
          <img :src="iconPerfil" class="img-titulo perfil" />
          <span class="titulo-texto perfil"> Perfil Digital </span>
          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[1] != isFlipping && tipoExibidoLinhas[1] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[1] != isFlipping &&
                (tipoExibidoLinhas[1] == 'brasil' ||
                  tipoExibidoLinhas[1] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[1]"
              v-show="flipCards[1] != isFlipping"
            />
          </transition>
        </div>
        <PerfilDigital
          :tipoExibido="tipoExibidoLinhas[1]"
          :resultados="resultados"
          :lado="'verso'"
        />
      </div>
    </div>
    <div class="sonar-box horiz sb-3">
      <div
        :class="['sonar-box-linha', { flipping: flipCards[2] }]"
        @mousedown="handleMouseDown('2')"
        @mouseup="handleMouseUp('2')"
        @mouseleave="handleMouseLeave('2')"
      >
        <div class="frente">
          <div
            :class="['box-titulo', 'horiz', tipoExibidoLinhas[2]]"
            id="BlocoSonar-saude-frente"
          >
            <img class="img-titulo" :src="saudeBemEstar" />
          </div>
          <SaudeBemEstar
            :tipoExibido="tipoExibidoLinhas[2]"
            :resultados="resultados"
          />
        </div>
        <div class="verso" v-if="!gerandoPdf">
          <div
            :class="['box-titulo', 'horiz', tipoExibidoLinhas[2]]"
            id="BlocoSonar-saude-verso"
          >
            <img class="img-titulo" :src="saudeBemEstar" />
          </div>
          <SaudeBemEstar
            :tipoExibido="tipoExibidoLinhas[2]"
            :resultados="resultados"
          />
        </div>
      </div>
      <div
        :class="['sonar-box-linha', { flipping: flipCards[3] }]"
        @mousedown="handleMouseDown('3')"
        @mouseup="handleMouseUp('3')"
        @mouseleave="handleMouseLeave('3')"
      >
        <div class="frente">
          <div
            :class="['box-titulo', 'horiz', tipoExibidoLinhas[3]]"
            id="BlocoSonar-moradia-frente"
          >
            <img class="img-titulo" :src="moradia" />
          </div>
          <Moradia
            :tipoExibido="tipoExibidoLinhas[3]"
            :resultados="resultados"
          />
        </div>
        <div class="verso" v-if="!gerandoPdf">
          <div
            :class="['box-titulo', 'horiz', tipoExibidoLinhas[3]]"
            id="BlocoSonar-moradia-verso"
          >
            <img class="img-titulo" :src="moradia" />
          </div>
          <Moradia
            :tipoExibido="tipoExibidoLinhas[3]"
            :resultados="resultados"
          />
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target + '-frente'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="viewport"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target + '-verso'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="viewport"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapWritableState, mapActions } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import DistanciaPontosInteresse from "@/components/SuperBox/SonarCards/DistanciaPontosInteresse.vue";
import PerfilDigital from "@/components/SuperBox/SonarCards/PerfilDigital.vue";
import SaudeBemEstar from "@/components/SuperBox/SonarCards/SaudeBemEstar.vue";
import Moradia from "@/components/SuperBox/SonarCards/Moradia.vue";

export default {
  name: "BlocoPFSonarLinha3",

  components: {
    DistanciaPontosInteresse,
    PerfilDigital,
    SaudeBemEstar,
    Moradia,
  },

  props: {
    tipoExibido: String,
    isFlipping: Boolean,
    isFading: Boolean,
    gerandoPdf: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      saudeBemEstar: "/img/superbox/bloco-sonar/saúdeeBemEstar.png",
      moradia: "/img/superbox/bloco-sonar/moradia.png",
      iconDistancia: "/img/superbox/bloco-sonar/Distancia.png",
      iconPerfil: "/img/superbox/bloco-sonar/perfilDigital.png",

      perfilCpfDesligado: "/img/superbox/bloco-sonar/PerfilCPF-desligado.svg",
      perfilBrasilDesligado:
        "/img/superbox/bloco-sonar/PersonaBrasil-desligado.png",

      tipoExibidoLinhas: ["", "", "", ""],
      flipCards: [false, false, false, false],
      liberaTela: false,
    };
  },

  watch: {
    tipoExibido(newVal) {
      this.tipoExibidoLinhas = this.tipoExibidoLinhas.map(() => newVal);
    },

    isFlipping(newVal) {
      this.flipCards = this.flipCards.map(() => newVal);
    },
  },

  methods: {
    handleMouseDown(i) {
      this.flipCards[i] = !this.flipCards[i];
      this.$set(
        this.tipoExibidoLinhas,
        i,
        this.tipoExibidoLinhas[i] === "documento" ? "brasil" : "documento"
      );
    },

    handleMouseUp(i) {
      this.flipCards[i] = !this.flipCards[i];
      this.$set(this.tipoExibidoLinhas, i, this.tipoExibido);
    },

    handleMouseLeave(i) {
      if (this.flipCards[i] !== this.isFlipping) {
        this.flipCards[i] = !this.flipCards[i];
        this.$set(this.tipoExibidoLinhas, i, this.tipoExibido);
      }
    },
  },

  created() {},

  computed: {
    tooltips() {
      return listaTooltips.blocoSonar3;
    },
  },

  mounted() {
    this.tipoExibidoLinhas = this.tipoExibidoLinhas.map(() => this.tipoExibido);
    this.liberaTela = true;
  },
};
</script>

<style lang="scss" scoped>
.row-sonar {
  display: flex;
  height: 130px;
  width: 100%;
}

.sonar-box {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  perspective: 5000px;

  &.horiz {
    gap: 10px;
    background-color: transparent;
    flex: 1;
  }

  .box-titulo {
    background-color: #4356a5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    min-height: 35px;
    border-radius: 10px 10px 0 0;
    cursor: default;
    line-height: 14px;
    cursor: pointer;

    &.horiz {
      height: 100%;
      width: 85px;
      border-radius: 10px 0 0 10px;
    }
  }

  .titulo-texto {
    position: relative;
    &.dist {
      right: 15px;
    }
    &.perfil {
      right: 16px;
    }
  }

  .img-titulo {
    position: relative;
    width: 40px;

    &.dist {
      right: 45px;
      width: 30px;
    }
    &.perfil {
      right: 60px;
      width: 33px;
    }
  }
  .img-tipo {
    width: 25px;
    position: absolute;
    right: 5px;
    opacity: 0.6;
  }
}

.sonar-box-linha {
  display: flex;
  height: calc(50% - 5px);
  width: 100%;
  border-radius: 10px;

  .frente,
  .verso {
    flex-direction: row !important;
    height: 60px !important;
  }
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
