<template>
  <div class="content" ref="dados-cadastrais">
    <div class="icons">
      <button class="buttonSB" id="openClose1" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose1'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose1'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop1" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop1'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div
      class="dropdown"
      :class="{ 'dropdown--open': abreBloco }"
      id="Dados-Cadastrais"
      v-if="Object.keys(this.resultados).length != 0"
    >
      <HeaderBlocos :bloco="blocoPFDadosCadastrais" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="row">
          <span class="boxInfo sb-right sb-3">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-0'">CPF</p>
            <p class="infoSB text-send sb-3" id="infoCpf">
              {{ formatarCPF(valorCPF) }}
              <span
                v-if="!telaHistorico && !valorCPF.includes('*') && !gerandoPdf"
                class="material-symbols-outlined icon-send"
                @click="selecionaConsultaAdicional(valorCPF)"
              >
                send
              </span>
            </p>
          </span>
          <span class="boxInfo sb-4">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-1'">UF Emissão</p>
            <p class="infoSB sb-4" id="infoCpfuf">
              {{ resultados.uf_emissao_cpf_calculado }}
            </p>
          </span>
        </div>
        <div class="row">
          <span class="boxInfo sb-7">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-2'">
              Nome Completo
            </p>
            <p class="infoSB sb-7" id="infoNome">
              {{ nomeCompleto }}
            </p>
          </span>
        </div>
        <div class="row">
          <span class="boxInfo sb-right sb-3">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-3'">
              Data de Nascimento
            </p>
            <p class="infoSB sb-3" id="infoNasc">
              {{ formatarData(resultados.bk_101_001_data_nascimento) }}
            </p>
          </span>
          <span class="boxInfo sb-right sb-2">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-4'">Idade</p>
            <p class="infoSB sb-2" id="infoIdade">
              {{ resultados.idade_calculada }}
            </p>
          </span>
          <span class="boxInfo sb-2">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-5'">Signo</p>
            <p class="infoSB sb-2" id="infoSigno">
              {{ resultados.signo_calculado }}
            </p>
          </span>
        </div>
        <div class="bloco"></div>
        <div class="row">
          <span class="boxInfo sb-2">
            <p class="labelSB" :id="'BlocoPFDadosCadastrais-6'">
              Gênero Inferido
            </p>
            <span class="infoSB sb-2" id="infoGen">
              <span
                class="material-symbols-outlined iconGen"
                id="iconGenF"
                :class="{
                  active: resultados.bk_101_004_genero_inferido == 'F',
                }"
              >
                female
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'iconGenF'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Feminino
              </b-tooltip>
              <span
                class="material-symbols-outlined iconGen"
                id="iconGenM"
                :class="{
                  active: resultados.bk_101_004_genero_inferido == 'M',
                }"
              >
                male
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'iconGenM'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Masculino
              </b-tooltip>
              <span
                class="material-symbols-outlined iconGen"
                id="iconGenI"
                :class="{
                  active:
                    resultados.bk_101_004_genero_inferido == 'N' ||
                    resultados.bk_101_004_genero_inferido == 'I',
                }"
              >
                do_not_disturb_off
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'iconGenI'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Indisponível
              </b-tooltip>
            </span>
          </span>
        </div>
      </div>
      <div v-for="(tt, i) in tooltipsBloco1" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFDadosCadastrais",

  components: { HeaderBlocos },

  props: {
    listaBlocos: Array,
    valorCPF: String,
    nomeCompleto: String,
    resultados: Object,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PF");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarCPF(cpf) {
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");

        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3}|\*{3})\.(\d{3}|\*{3})\.(\d{3}|\*{3})-(\d{2}|\*{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  created() {},

  computed: {
    blocoPFDadosCadastrais() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao == "Dados Cadastrais"
      );
    },

    tooltipsBloco1() {
      return listaTooltips.bloco01;
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),

    ...mapWritableState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 5px 0px;
}

.boxInfo {
  #infoGen {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.labelSB {
  margin: 0 0 0 5px !important;
  font-size: 12px;
  color: #0070c0;
  width: fit-content;
  cursor: default;
  line-height: 18px;
}
.infoSB {
  height: 40px;
  margin: 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.iconGen {
  margin: 0;
  background-color: rgb(173, 173, 173);
  color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 25px;
  cursor: default;
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;

  &:hover {
    font-size: 22px;
  }
}

.active {
  background-color: #0070c0bb;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
