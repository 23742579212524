<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose3" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose3'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose3'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop3" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop3'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFGeolocalidades" />
      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
      >
        <div class="row" v-if="abreBloco">
          <div class="tableRow">
            <div
              class="tab"
              v-for="(item, i) in head"
              :key="item"
              :class="{
                'sb-1': i == 1 || i == 2 || i == 5 || i == 6 || i == 7,
                'sb-2': i == 4,
                'sb-4': i == 3,
                'sb-right': i != 7,
              }"
            >
              <div class="cabecTab azul" :id="'BlocoPFGeolocalidades-' + i">
                <!-- :class="{
                  'sb-1': i == 1 || i == 2 || i == 5 || i == 6 || i == 7,
                  'sb-2': i == 4,
                  'sb-4': i == 3,
                }" -->
                <span>
                  {{ item }}
                </span>
              </div>

              <div class="resultTab" v-for="linha in 5" :key="`linha-${linha}`">
                <template v-if="i != 2">
                  {{ resultadosTabelaCeps(item, linha) }}
                </template>
                <template v-if="i == 2" :id="'cpf-precisao-' + linha">
                  {{ resultadosTabelaCeps(item, linha) }}
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="detalhes">
          <B3Detalhes
            :resultados="resultadosTratados"
            :parametros="parametros"
            :index="indexOpen"
            :abreBloco="abreBloco"
            :codigo_agrupador="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :gerandoPdf="gerandoPdf"
          />
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
      <div v-if="abreBloco">
        <div v-for="(tt, i) in tooltipsBloco3" :key="i">
          <b-tooltip
            v-if="exibirTootipsConsultas"
            :target="tt.target"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="window"
          >
            <div v-html="tt.texto"></div>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import B3Detalhes from "@/components/SuperBox/B3Detalhes.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import Loading from "@/components/Loading";

export default {
  name: "BlocoPFGeolocalidades",

  components: {
    B3Detalhes,
    HeaderBlocos,
    Loading,
  },

  props: {
    listaBlocos: Array,
    resultados: Object,
    parametros: Object,
    codigo_agrupador: String,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      indexOpen: 0,
      arrowControl: [],
      abreBloco: false,
      resultadoTabela1: [],
      head: [
        "CEP",
        "Ranking",
        "Precisão",
        "Logradouro",
        "Município",
        "UF - DDD",
        "Score da Localidade",
        "Distância ao Princ.",
      ],

      resultadosCeps: [
        `bk_300_003_cep_$bk_300_003_cep_${this.index + 1}`,
        `bk_300_003_cep_${this.index + 1}_ddd`,
        `bk_300_003_cep_${this.index + 1}_distancia`,
        `bk_300_003_cep_${this.index + 1}_logradouro`,
        `bk_300_003_cep_${this.index + 1}_municipio`,
        `bk_300_003_cep_${this.index + 1}_precisao`,
        `bk_300_003_cep_${this.index + 1}_tipo_logradouro`,
        `bk_300_003_cep_${this.index + 1}_uf`,
      ],
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    resultadosTabelaCeps(item, linha) {
      const tipoLogradouroKey = `bk_300_003_cep_${linha}_tipo_logradouro`;
      const logradouroKey = `bk_300_003_cep_${linha}_logradouro`;

      for (const chave in this.resultados) {
        if (
          this.resultados[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_") &&
          !chave.includes("_indicador_")
        ) {
          this.resultados[chave] = "-";
        }
      }

      const tipoLogradouro = this.resultados[tipoLogradouroKey];
      const logradouro = this.resultados[logradouroKey];
      let logradouroFinal = logradouro;
      if (tipoLogradouro !== "-" && tipoLogradouro !== null) {
        logradouroFinal = `${tipoLogradouro} ${logradouro}`;
      }

      const suffixMap = {
        CEP: this.formatarCEP(this.resultados[`bk_300_003_cep_${linha}`]),
        Ranking: linha === 1 ? "Principal" : `Adicional ${linha - 1}`,
        Precisão: this.resultados[`bk_300_003_cep_${linha}_precisao`],
        Logradouro: logradouroFinal,
        Município: this.resultados[`bk_300_003_cep_${linha}_municipio`],
        "UF - DDD":
          this.resultados[`bk_300_003_cep_${linha}_uf`] +
          " - " +
          this.resultados[`bk_300_003_cep_${linha}_ddd`],
        "Score da Localidade": this.resultados[`bk_300_003_cep_${linha}_score`],
        "Distância ao Princ.": this.formatarNumeroComPonto(
          this.resultados[`bk_300_003_cep_${linha}_distancia`]
        ),
      };

      Object.keys(suffixMap).forEach((key) => {
        if (suffixMap[key] === null && !key.includes("indicador")) {
          suffixMap[key] = "-";
        }
        if (suffixMap["CEP"] === "-") {
          suffixMap["Ranking"] = "-";
        }
      });

      return suffixMap[item];
    },

    formatarCEP(numero) {
      if (numero != null && numero != "-") {
        let cepLimpo = numero.replace(/\D/g, "");

        if (cepLimpo.length !== 8) {
          return numero;
        }

        numero = cepLimpo.replace(/^(\d{5})(\d{3})$/, "$1-$2");
      }

      return numero;
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-" && numero != null) {
        if (numero == 0) {
          var numeroString = numero.toString().replace(".", ",");
          const partes = numeroString.split(",");
          partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return partes.join(",");
        } else {
          var numeroString = Number(numero)
            .toFixed(1)
            .toString()
            .replace(".", ",");
          const partes = numeroString.split(",");
          partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return partes.join(",") + " km";
        }
      } else {
        return numero;
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    blocoPFGeolocalidades() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Endereços e Geolocalidades"
      );
    },

    msgErro() {
      const chave = this.blocoPFGeolocalidades.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    resultadosTratados() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (objetoCopiado[chave] === null && !chave.includes("indicador")) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    tooltipsBloco3() {
      return listaTooltips.bloco03;
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    ...mapWritableState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
  justify-content: center;
}

.detalhes {
  height: auto;
  overflow: hidden;
  margin: 1% 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: height 5s ease;
}

.tableRow {
  display: flex;
  margin: 0 0px 20px 0px;
  cursor: default;
  max-width: 950px;
}

.tab {
  min-width: 75px;
}

.cabecTab {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin: 0 0px 0 0;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.azul {
    background-color: #d9e1f2;
    color: #363636 !important;
    border-radius: 5px;
  }
}
.resultTab {
  height: 40px;
  width: 100%;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 5px 0px 0 0;
}

.linha-cabecalho {
  display: flex;
  width: 950px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.cabecalho-cell {
  padding: 0;
  margin: 0;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 5px 0 0 !important;
  height: 40px;
}

.fundoAzul {
  background-color: #d9e1f2;
  color: #363636 !important;
  border-radius: 5px;
}
</style>
